import React from "react";
import Sidebar from "../Components/SideBar";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import ApplyLeave from "../Components/ApplyLeave";
import Profile from "../Components/Profile";
import EmployeeDashboard from "../Components/EmployeeDashboard";
import PrivateRoute from "../Components/PrivateRoute"; // Import PrivateRoute
import Footer from "../Components/Footer";

const EmployeePanel = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate(); // Use navigate to programmatically navigate
  return (
    <>
      <div className="flex flex-row bg-gray-100 min-h-screen">
        <Sidebar role="employee" />
        <div className="flex-grow flex flex-col">
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <EmployeeDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/apply-leave"
              element={
                <PrivateRoute>
                  <ApplyLeave />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route path="/leave-policy" element={<Footer />} />
          </Routes>
          <footer className="mt-auto p-4 bg-gray-200 text-center flex justify-center items-center">
            <Link
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                navigate("/employeepanel/leave-policy"); // Navigate to the Footer component
              }}
              className="text-blue-500 hover:underline"
            >
              Leave policy&nbsp;
            </Link>

            <p>Exine Labs &copy; {currentYear} </p>
          </footer>
        </div>
      </div>
    </>
  );
};

export default EmployeePanel;
