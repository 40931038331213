import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateProfilePicture, fetchProfile } from "../redux/EmployeeSlice"; // Adjust import based on your folder structure

const UpdateProfileModal = ({ isOpen, onClose }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleProfilePictureChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setError("Please select a profile picture.");
      return;
    }

    setIsLoading(true);
    setError(null); // Clear any previous errors

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Dispatch the action to update the profile picture
      const resultAction = await dispatch(
        updateProfilePicture(formData)
      ).unwrap();

      // Optionally, display a success message or perform additional actions
      console.log("Profile picture updated successfully", resultAction);

      // Dispatch the action to fetch employees after updating the profile picture
      await dispatch(fetchProfile());

      setFile(null); // Clear file input
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg z-50">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4">Update Profile</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="file"
              className="block text-gray-600 font-semibold mb-2"
            >
              Profile Picture
            </label>
            <input
              type="file"
              id="file"
              onChange={handleProfilePictureChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update Profile Picture"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfileModal;
