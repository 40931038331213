// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./AuthSlics";
import employeeReducer from "./EmployeeSlice";
import leaveRequestsReducer from "./LeaveRequestslice";
import rolereducer from "./RoleSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    employees: employeeReducer,
    leaveRequests: leaveRequestsReducer,
    roles: rolereducer,
  },
});

export default store;
