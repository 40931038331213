// src/api.js
import axios from "axios";

// Define the base URL for your API
const BASE_URL = "https://hub.exinelabs.com";

// Create an Axios instance without a default token
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to add the Authorization header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      // Log out the user
      console.log("Unauthorized access. Logging out...");

      localStorage.removeItem("token"); // Remove the token
      window.location.href = "/"; // Redirect to the login page
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
