import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyLeave, fetchLeaveHistory } from "../redux/LeaveRequestslice"; // Adjust the import path as necessary

const LeaveFormModal = ({ isOpen, onRequestClose, setFlashMessage }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leaveType, setLeaveType] = useState("annual");
  const [applyFor, setApplyFor] = useState("full-day");
  const [reason, setReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.leaveRequests);

  const calculateLeaveDays = (fromDate, toDate) => {
    if (applyFor === "half-day") {
      return 0.5;
    }

    const start = new Date(fromDate);
    const end = new Date(toDate);
    const differenceInTime = end - start;
    const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;
    return differenceInDays;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const leaveRequest = {
      startDate: fromDate,
      endDate: toDate,
      reason,
      leaveType,
      applyFor, // Added applyFor here
    };

    try {
      const resultAction = await dispatch(applyLeave(leaveRequest));

      if (applyLeave.fulfilled.match(resultAction)) {
        setFlashMessage("Leave request submitted successfully");
        dispatch(fetchLeaveHistory()); // Fetch the updated leave history
        handleClose(); // Close the modal after successful submission
      } else if (applyLeave.rejected.match(resultAction)) {
        // Extract and display the error message from resultAction.payload
        const error = resultAction.payload?.error || "An error occurred";
        setErrorMessage(error);
      }
    } catch (error) {
      setFlashMessage("Leave request not submitted");
      setErrorMessage("An error occurred while submitting the leave request.");
    }
  };

  const handleClose = () => {
    // Reset form fields when closing
    setFromDate("");
    setToDate("");
    setLeaveType("annual");
    setApplyFor("full-day");
    setReason("");
    setErrorMessage("");
    setSuccessMessage("");
    onRequestClose(); // Close the modal
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="modal bg-white p-6 rounded shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Apply Leave</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              From Date
            </label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              To Date
            </label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Number of Days
            </label>
            <input
              type="text"
              value={
                fromDate && toDate ? calculateLeaveDays(fromDate, toDate) : ""
              }
              readOnly
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Leave Type
            </label>
            <select
              value={leaveType}
              onChange={(e) => setLeaveType(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="annual">Annual Leave</option>
              <option value="unplanned">Unplanned Leave </option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Apply For
            </label>
            <select
              value={applyFor}
              onChange={(e) => setApplyFor(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="full-day">Full Day</option>
              <option value="half-day">Half Day</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Reason
            </label>
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="4"
              required
            ></textarea>
          </div>
          {errorMessage && (
            <div className="mb-4 text-red-500">{errorMessage}</div>
          )}
          {successMessage && (
            <div className="mb-4 text-green-500">{successMessage}</div>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleClose}
              className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeaveFormModal;
