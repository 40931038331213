import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://hub.exinelabs.com";

// Helper function to get the token
const getAuthToken = () => `Bearer ${localStorage.getItem("token")}`;

// Create Role Thunk
export const createRole = createAsyncThunk(
  "roles/createRole",
  async (roleData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/create-role`,
        roleData,
        {
          headers: {
            Authorization: getAuthToken(),
          },
        }
      );
      return response.data; // Assuming response.data has { role, message }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Roles Thunk
export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/role/get-roles`, {
        headers: {
          Authorization: getAuthToken(),
        },
      });
      return response.data; // Assuming response.data is an array of roles
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Role Name Thunk
export const updateRoleName = createAsyncThunk(
  "roles/updateRoleName",
  async ({ id, roleName }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/role/update-role-name/${id}`,
        { roleName },
        {
          headers: {
            Authorization: getAuthToken(),
          },
        }
      );
      return response.data; // Assuming response.data has { role, message }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Role Status Thunk
export const updateRoleStatus = createAsyncThunk(
  "roles/updateRoleStatus",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/role/update-role-status/${id}`,
        { status },
        {
          headers: {
            Authorization: getAuthToken(),
          },
        }
      );
      return response.data; // Assuming response.data has { role, message }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Role Thunk
export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/role/delete-role/${id}`,
        {
          headers: {
            Authorization: getAuthToken(),
          },
        }
      );
      return { id, message: response.data.message }; // Ensure consistent structure
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// In RoleSlice.js
export const fetchActiveRoles = createAsyncThunk(
  "roles/fetchActiveRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/role/get-active-roles`, {
        headers: {
          Authorization: getAuthToken(),
        },
      });
      return response.data; // Array of active roles
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Network Error" }
      );
    }
  }
);

// Initial State
const initialState = {
  roles: [],
  activeRoles: [],
  status: "idle",
  createRoleStatus: "idle",
  updateRoleStatus: "idle",
  deleteRoleStatus: "idle",

  // Separate error and success messages for each action
  createRoleError: null,
  createRoleSuccessMessage: null,

  updateRoleError: null,
  updateRoleSuccessMessage: null,

  deleteRoleError: null,
  deleteRoleSuccessMessage: null,
};

// Role Slice
const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    clearCreateRoleMessages: (state) => {
      state.createRoleError = null;
      state.createRoleSuccessMessage = null;
    },
    clearUpdateRoleMessages: (state) => {
      state.updateRoleError = null;
      state.updateRoleSuccessMessage = null;
    },
    clearDeleteRoleMessages: (state) => {
      state.deleteRoleError = null;
      state.deleteRoleSuccessMessage = null;
    },
  },
  extraReducers: (builder) => {
    // Handle Create Role
    builder
      .addCase(createRole.pending, (state) => {
        state.createRoleStatus = "loading";
        state.createRoleError = null;
        state.createRoleSuccessMessage = null;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.createRoleStatus = "succeeded";
        state.roles.push(action.payload.role); // Add the new role
        state.createRoleSuccessMessage = action.payload.message;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.createRoleStatus = "failed";
        state.createRoleError =
          action.payload.message || "Failed to create role.";
      });

    // Handle Fetch Roles
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
        // You can have fetchRolesError if needed
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = action.payload; // Replace roles with fetched roles
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = "failed";
        // Handle fetchRoles error if needed
      });

    // Handle Update Role Name
    builder
      .addCase(updateRoleName.pending, (state) => {
        state.updateRoleStatus = "loading";
        state.updateRoleError = null;
        state.updateRoleSuccessMessage = null;
      })
      .addCase(updateRoleName.fulfilled, (state, action) => {
        state.updateRoleStatus = "succeeded";
        const updatedRole = action.payload.role;
        state.roles = state.roles.map((role) =>
          role._id === updatedRole._id ? updatedRole : role
        );
        state.updateRoleSuccessMessage = action.payload.message;
      })
      .addCase(updateRoleName.rejected, (state, action) => {
        state.updateRoleStatus = "failed";
        state.updateRoleError =
          action.payload.message || "Failed to update role name.";
      });

    // Handle Update Role Status
    builder
      .addCase(updateRoleStatus.pending, (state) => {
        state.updateRoleStatus = "loading";
        state.updateRoleError = null;
        state.updateRoleSuccessMessage = null;
      })
      .addCase(updateRoleStatus.fulfilled, (state, action) => {
        state.updateRoleStatus = "succeeded";
        const updatedRole = action.payload.role;
        state.roles = state.roles.map((role) =>
          role._id === updatedRole._id ? updatedRole : role
        );
        state.updateRoleSuccessMessage = action.payload.message;
      })
      .addCase(updateRoleStatus.rejected, (state, action) => {
        state.updateRoleStatus = "failed";
        state.updateRoleError =
          action.payload.message || "Failed to update role status.";
      });

    // Handle Delete Role
    builder
      .addCase(deleteRole.pending, (state) => {
        state.deleteRoleStatus = "loading";
        state.deleteRoleError = null;
        state.deleteRoleSuccessMessage = null;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.deleteRoleStatus = "succeeded";
        state.roles = state.roles.filter(
          (role) => role._id !== action.payload.id
        );
        state.deleteRoleSuccessMessage = action.payload.message;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.deleteRoleStatus = "failed";
        state.deleteRoleError =
          action.payload.message || "Failed to delete role.";
      });

    // Handle Fetch Active Roles
    builder
      .addCase(fetchActiveRoles.pending, (state) => {
        state.fetchActiveRolesStatus = "loading";
        state.fetchActiveRolesError = null;
      })
      .addCase(fetchActiveRoles.fulfilled, (state, action) => {
        state.fetchActiveRolesStatus = "succeeded";
        state.activeRoles = action.payload; // Add a new state property if needed
      })
      .addCase(fetchActiveRoles.rejected, (state, action) => {
        state.fetchActiveRolesStatus = "failed";
        state.fetchActiveRolesError =
          action.payload.message || "Failed to fetch active roles.";
      });
  },
});

export const {
  clearCreateRoleMessages,
  clearUpdateRoleMessages,
  clearDeleteRoleMessages,
} = roleSlice.actions;
export default roleSlice.reducer;
