import React, { useEffect } from "react";

const EmployeeDetailsModal = ({ isOpen, onClose, employee }) => {
  useEffect(() => {
    if (employee && employee.profilePicture) {
      console.log("Profile Picture URL:", employee.profilePicture);
    }
  }, [employee]);

  if (!isOpen || !employee) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50 z-40"
        onClick={onClose}
      ></div>
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl z-50">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Employee Details
        </h2>
        <div className="flex flex-col items-center mb-6">
          <img
            src={`https://hub.exinelabs.com/${employee.profilePicture}`}
            alt="Profile"
            className="w-24 h-24 rounded-full mb-4"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="mb-4">
            <strong>Employee ID:</strong> {employee.employeeId}
          </div>
          <div className="mb-4">
            <strong>Name:</strong> {employee.firstName} {employee.lastName}
          </div>
          <div className="mb-4">
            <strong>Email:</strong> {employee.email}
          </div>
          <div className="mb-4">
            <strong>Joined Date:</strong>{" "}
            {new Date(employee.joiningDate).toLocaleDateString()}
          </div>
          <div className="mb-4">
            <strong>Status:</strong> {employee.status}
          </div>
          <div className="mb-4">
            <strong>Date of Birth:</strong>{" "}
            {new Date(employee.dateOfBirth).toLocaleDateString()}
          </div>
          <div className="mb-4">
            <strong>Role:</strong> {employee.role}
          </div>
          <div className="mb-4">
            <strong>User:</strong> {employee.user || "N/A"}
          </div>
          <div className="mb-4">
            <strong>Salary INR:</strong> {employee.salary}
          </div>
          <div className="mb-4">
            <strong>Address:</strong> {employee.address}
          </div>
          <div className="mb-4">
            <strong>Annual Leave Days:</strong> {employee.annualLeaveDays}
          </div>
        </div>
        <div className="text-center mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailsModal;
