import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./Api"; // Import axiosInstance from AxiosInstance.js
import { logout as authLogout } from "./AuthSlics"; // Import logout action from authSlice

// // Define the base URL for your API
// const BASE_URL = "http://localhost:5000";

// // Create an Axios instance without a default token
// const axiosInstance = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // Add a request interceptor to add the Authorization header dynamically
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// Async thunk for fetching employees
export const fetchEmployees = createAsyncThunk(
  "employees/fetchEmployees",
  async ({ employeeId = "", name = "" } = {}, { rejectWithValue }) => {
    try {
      let url = "/user/get-Employees";
      if (employeeId || name) {
        url = `/user/search-employees?`;
        if (employeeId) url += `employeeId=${employeeId}&`;
        if (name) url += `name=${name}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: error.message }
      );
    }
  }
);

// Async thunk for updating employee status
export const updateEmployeeStatus = createAsyncThunk(
  "employees/updateEmployeeStatus",
  async ({ employeeId, newStatus }, { rejectWithValue }) => {
    try {
      await axiosInstance.patch(`/user/update-status/${employeeId}`, {
        status: newStatus,
      });
      return { employeeId, newStatus };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: error.message }
      );
    }
  }
);

// Async thunk for fetching profile
export const fetchProfile = createAsyncThunk(
  "employees/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/user/employee/profile");
      console.log("Fetched profile data:", response.data);

      const data = response.data;
      data.profilePicture = `${response.config.baseURL}/${data.profilePicture}`;
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: error.message }
      );
    }
  }
);

// Async thunk for adding a new employee
export const addEmployee = createAsyncThunk(
  "employees/addEmployee",
  async (employeeData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/user/create-Employees",
        employeeData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || {
          message: "An unexpected error occurred. Please try again.",
        }
      );
    }
  }
);

// Async thunk for updating employee details, excluding profile picture
export const updateEmployeeDetails = createAsyncThunk(
  "employees/updateEmployeeDetails",
  async ({ employeeId, updatedFields }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/user/update-employee/${employeeId}`,
        updatedFields
      );
      const updatedEmployee = response.data;
      if (updatedEmployee.profilePicture) {
        updatedEmployee.profilePicture = `${response.config.baseURL}/${updatedEmployee.profilePicture}`;
      }
      return updatedEmployee;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: error.message }
      );
    }
  }
);

// Async thunk for updating the profile picture
export const updateProfilePicture = createAsyncThunk(
  "employees/updateProfilePicture",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        "/user/update-profile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const updatedProfile = response.data;
      updatedProfile.profilePicture = `${response.config.baseURL}/${updatedProfile.profilePicture}`;
      return updatedProfile;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: error.message }
      );
    }
  }
);

const employeeSlice = createSlice({
  name: "employees",
  initialState: {
    list: [],
    loading: false,
    error: null,
    statusFilter: "all",
    currentPage: 1,
    flashMessage: "",
    profile: null,
  },
  reducers: {
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFlashMessage: (state, action) => {
      state.flashMessage = action.payload;
    },
    clearFlashMessage: (state) => {
      state.flashMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch employees";
      })
      .addCase(updateEmployeeStatus.fulfilled, (state, action) => {
        const { employeeId, newStatus } = action.payload;
        const employee = state.list.find((emp) => emp._id === employeeId);
        if (employee) employee.status = newStatus;
      })
      .addCase(updateEmployeeStatus.rejected, (state, action) => {
        state.error =
          action.payload?.message || "Failed to update employee status";
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch profile";
      })
      .addCase(addEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to add employee";
      })
      .addCase(updateEmployeeDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmployeeDetails.fulfilled, (state, action) => {
        state.loading = false;
        const updatedEmployee = action.payload;
        const existingEmployee = state.list.find(
          (emp) => emp._id === updatedEmployee._id
        );
        if (existingEmployee) {
          Object.assign(existingEmployee, updatedEmployee);
        }
        if (state.profile && state.profile._id === updatedEmployee._id) {
          state.profile = updatedEmployee;
        }
      })
      .addCase(updateEmployeeDetails.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.message || "Failed to update employee details";
      })
      .addCase(updateProfilePicture.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.loading = false;
        if (state.profile) {
          state.profile.profilePicture = action.payload.profilePicture;
        }
        // Dispatch fetchProfile to ensure profile details are up-to-date
        // Dispatch is not directly supported in extraReducers, so you will need to call fetchProfile from the component
      })
      .addCase(updateProfilePicture.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.message || "Failed to update profile picture";
      })
      .addCase(authLogout.fulfilled, (state) => {
        state.list = [];
        state.profile = null;
      });
  },
});

export const {
  setStatusFilter,
  setCurrentPage,
  setFlashMessage,
  clearFlashMessage,
} = employeeSlice.actions;

export default employeeSlice.reducer;
