import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PasswordResetModal from "./PasswordResetModal";
import { fetchEmployees } from "../redux/EmployeeSlice";

const Navbar = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();

  const getTitle = () => {
    switch (location.pathname) {
      case "/employeepanel":
        return "Home";
      case "/employeepanel/apply-leave":
        return "Apply Leave";
      case "/employeepanel/profile":
        return "Profile";
      case "/adminpanel":
        return "Home";
      case "/adminpanel/employeeDetails":
        return "Employee Details";
      case "/adminpanel/leaverequests":
        return "Leave Requests";
      case "/adminpanel/create-role":
        return "Create Role";
      case "/adminpanel/leave-policy":
        return "Leave Policy";
      case "/adminpanel/profile":
        return "Profile";
      default:
        return "Dashboard";
    }
  };

  const showSearchBar = () => {
    return location.pathname === "/adminpanel/employeeDetails";
  };

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const openPasswordResetModal = () => {
    setIsPasswordResetModalOpen(true);
    setIsSettingsOpen(false);
  };

  const closePasswordResetModal = () => {
    setIsPasswordResetModalOpen(false);
  };

  const handleSearch = () => {
    const isEmployeeId = /^EL\d+$/.test(searchQuery);
    dispatch(
      fetchEmployees({
        employeeId: isEmployeeId ? searchQuery : "",
        name: isEmployeeId ? "" : searchQuery,
      })
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // Update search query and convert employee ID pattern to uppercase
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    const isEmployeeId = /^el\d*$/i.test(input); // Check if it looks like an employee ID (starts with "el")

    // Convert to uppercase if it matches employee ID pattern
    if (isEmployeeId) {
      setSearchQuery(input.toUpperCase());
    } else {
      setSearchQuery(input);
    }
  };

  return (
    <div className="flex items-center justify-between bg-white p-4 relative">
      <div className="text-2xl font-semibold text-black">{getTitle()}</div>

      {showSearchBar() && (
        <div className="flex-grow mx-4 relative">
          <input
            type="text"
            placeholder="Search (EmployeeId/Name)..."
            value={searchQuery}
            onChange={handleSearchInputChange} // Update input change handler
            onKeyDown={handleKeyDown}
            className="w-full px-4 py-2 pl-10 rounded bg-gray-200 text-black focus:outline-none focus:bg-gray-300"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
            onClick={handleSearch}
          />
        </div>
      )}

      <div className="flex items-center space-x-4">
        <div className="relative">
          <button
            className="focus:outline-none"
            onClick={toggleSettingsDropdown}
          >
            <FontAwesomeIcon icon={faCog} className="text-xl text-black" />
          </button>
          {isSettingsOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg cursor-pointer">
              <ul className="py-1">
                <li
                  className="px-4 py-2 hover:bg-gray-100"
                  onClick={openPasswordResetModal}
                >
                  Reset Password
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <PasswordResetModal
        isOpen={isPasswordResetModalOpen}
        onClose={closePasswordResetModal}
      />
    </div>
  );
};

export default Navbar;
