import React from "react";

const Footer = () => {
  // Static values for the leave policy
  const currentYear = new Date().getFullYear();
  const numberOfLeaves = 20;
  const monthStart = "January";
  const monthEnd = "December";

  return (
    <div className="h-full">
      <div className=" p-8 bg-white flex flex-col  h-full w-full">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Leave Policy {currentYear}
        </h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-700 ">
            Leave Quota for {currentYear}
          </h2>
          <p className="text-gray-600 mt-2 ">
            You are allocated a total of{" "}
            <span className="font-bold text-gray-800">{numberOfLeaves}</span>{" "}
            leaves in a year, beginning{" "}
            <span className="font-bold text-gray-800">
              {monthStart} {currentYear}
            </span>{" "}
            till{" "}
            <span className="font-bold text-gray-800">
              {monthEnd} {currentYear}
            </span>
            . You can consume this leave in the same year they are
            accrued/credited.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-700 ">
            Applying for a Leave
          </h2>
          <p className="text-gray-600 mt-2 ">
            You can apply for a full day, as well as half-day of Annual Leave.
            The leaves will not be auto-approved after you apply. You can apply
            for the leave on your own or a manager can apply on your behalf.
          </p>
        </section>

        {/* Restriction Section */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-700 ">
            Restrictions for Applying Leave
          </h2>
          <p className="text-gray-600 mt-2 ">
            5 You can apply for leave for a past day, but not beyond 365
            calendar days back.
          </p>
        </section>

        {/* Leave Balances Section */}
        <section>
          <h2 className="text-xl font-semibold text-gray-700 ">
            Leave Balances at the End of the Year
          </h2>
          <p className="text-gray-600 mt-2 ">
            All leave balances expire or get reset by{" "}
            <span className="font-bold text-gray-800">
              {monthEnd} {currentYear}
            </span>
            . Carried forward leave expires after 1 day from the start of the
            new calendar year, beginning on{" "}
            <span className="font-bold text-gray-800">
              January 1, {currentYear + 1}
            </span>
            .
          </p>
        </section>

        {/* Footer with additional information */}
        <footer className="mt-8  text-sm text-gray-500">
          For more details, please contact HR.
        </footer>
      </div>
    </div>
  );
};

export default Footer;
