import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Sidebar from "../Components/SideBar";
import Navbar from "../Components/Navbar";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import EmployeeDetails from "../Components/Employee_deatails";
import LeaveRequests from "../Components/Leave_Requests";
import Profile from "../Components/Profile";
import AdminDashboard from "../Components/AdminDashboard";
import ApplyLeave from "../Components/ApplyLeave";
import { fetchEmployees } from "../redux/EmployeeSlice";
import CreateRole from "../Components/CreateRole";
import PrivateRoute from "../Components/PrivateRoute";
import Footer from "../Components/Footer"; // Import Footer component

const AdminPanel = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate to programmatically navigate

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  return (
    <div className="flex flex-row bg-gray-100 min-h-screen">
      <Sidebar role="admin" />
      <div className="flex-grow flex flex-col">
        <Navbar />
        <PrivateRoute>
          <Routes>
            <Route path="/" element={<AdminDashboard />} />
            <Route path="/employeeDetails" element={<EmployeeDetails />} />
            <Route path="/leaverequests" element={<LeaveRequests />} />
            <Route path="/apply-leave" element={<ApplyLeave />} />
            <Route path="/create-role" element={<CreateRole />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/leave-policy" element={<Footer />} />
          </Routes>
        </PrivateRoute>
        <footer className="mt-auto p-4 bg-gray-200 text-center flex justify-center items-center">
          <Link
            onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              navigate("/adminpanel/leave-policy"); // Navigate to the Footer component
            }}
            className="text-blue-500 hover:underline"
          >
            Leave policy&nbsp;
          </Link>

          <p>Exine Labs &copy; {currentYear} </p>
        </footer>
      </div>
    </div>
  );
};

export default AdminPanel;
