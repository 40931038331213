import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployeeDetails, fetchEmployees } from "../redux/EmployeeSlice";
import { fetchActiveRoles } from "../redux/RoleSlice";

const EditEmployeeModal = ({ isOpen, onClose, employee }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    joiningDate: "",
    dateOfBirth: "",
    salary: "",
    address: "",
    user: "",
    role: "",
  });

  const roles = useSelector((state) => state.roles.activeRoles);
  // Fetch roles when the modal is opened
  useEffect(() => {
    if (isOpen) {
      dispatch(fetchActiveRoles());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (employee) {
      setFormData({
        firstName: employee.firstName || "",
        lastName: employee.lastName || "",
        email: employee.email || "",
        username: employee.username || "",
        joiningDate: employee.joiningDate
          ? new Date(employee.joiningDate).toISOString().split("T")[0]
          : "",
        dateOfBirth: employee.dateOfBirth
          ? new Date(employee.dateOfBirth).toISOString().split("T")[0]
          : "",
        salary: employee.salary || "",
        address: employee.address || "",
        user: employee.user || "",
        role: employee.role || "",
      });
    }
  }, [employee]);

  if (!isOpen || !employee) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await dispatch(
        updateEmployeeDetails({
          employeeId: employee._id,
          updatedFields: formData,
        })
      ).unwrap();

      // After updating, trigger a fetch to refresh the employee list
      await dispatch(fetchEmployees());

      onClose();
    } catch (error) {
      console.error("Error updating employee details:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-white p-10 rounded-lg w-2/3">
        <h2 className="text-lg font-bold mb-5 text-center">
          Update Employee Details
        </h2>

        <form onSubmit={handleUpdate}>
          {/* First Name and Last Name */}
          <div className="flex mb-4">
            <div className="w-1/2 mr-2">
              <label className="block font-medium">First Name</label>
              <input
                type="text"
                name="firstName"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.firstName}
              />
            </div>
            <div className="w-1/2 ml-2">
              <label className="block font-medium">Last Name</label>
              <input
                type="text"
                name="lastName"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.lastName}
              />
            </div>
          </div>

          {/* Email and Username */}
          <div className="flex mb-4">
            <div className="w-1/2 mr-2">
              <label className="block font-medium">Email</label>
              <input
                type="email"
                name="email"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.email}
              />
            </div>
            <div className="w-1/2 ml-2">
              <label className="block font-medium">Username</label>
              <input
                type="text"
                name="username"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.username}
              />
            </div>
          </div>

          {/* Joining Date and Date of Birth */}
          <div className="flex mb-4">
            <div className="w-1/2 mr-2">
              <label className="block font-medium">Joining Date</label>
              <input
                type="date"
                name="joiningDate"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.joiningDate}
              />
            </div>
            <div className="w-1/2 ml-2">
              <label className="block font-medium">Date of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.dateOfBirth}
              />
            </div>
          </div>

          {/* Salary and Address */}
          <div className="flex mb-4">
            <div className="w-1/2 mr-2">
              <label className="block font-medium">Salary</label>
              <input
                type="number"
                name="salary"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.salary}
              />
            </div>
            <div className="w-1/2 ml-2">
              <label className="block font-medium">Address</label>
              <textarea
                name="address"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.address}
              />
            </div>
          </div>

          {/* user and role */}
          <div className="flex mb-4">
            <div className="w-1/2 mr-2">
              <label className="block font-medium">User</label>
              <select
                name="user"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.user}
              >
                <option value="">Select user</option>
                <option value="admin">Admin</option>
                <option value="non-admin">Employee</option>
              </select>
            </div>
            <div className="w-1/2 ml-2">
              <label className="block font-medium">Role</label>
              <select
                name="role"
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-gray-700"
                onChange={handleChange}
                value={formData.role}
              >
                <option value="">Select Role</option>
                {roles.map((role) => (
                  <option key={role._id} value={role.roleName}>
                    {role.roleName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Update Details
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEmployeeModal;
