import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchRoles,
  createRole,
  updateRoleName,
  updateRoleStatus,
  deleteRole,
  clearDeleteRoleMessages,
  clearUpdateRoleMessages, // Import the clear action
} from "../redux/RoleSlice"; // Update the path as needed
import CreateRoleModal from "./CreateRoleModal";
import EditRoleModal from "./EditRoleModal";

const CreateRole = () => {
  const dispatch = useDispatch();
  const {
    roles,
    status,
    deleteRoleError,
    deleteRoleSuccessMessage,
    updateRoleError,
    updateRoleSuccessMessage,
  } = useSelector((state) => state.roles);

  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);
  const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Local state for flash messages specific to this component
  const [flashMessage, setFlashMessage] = useState("");
  const [showFlashMessage, setShowFlashMessage] = useState(false);

  const rolesPerPage = 10;

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  // Effect to handle delete and update role messages
  useEffect(() => {
    if (
      deleteRoleSuccessMessage ||
      deleteRoleError ||
      updateRoleSuccessMessage ||
      updateRoleError
    ) {
      // Determine which message to show based on priority or existence
      let message =
        deleteRoleSuccessMessage ||
        deleteRoleError ||
        updateRoleSuccessMessage ||
        updateRoleError;
      setFlashMessage(message);
      setShowFlashMessage(true);

      // Automatically hide the message after 3 seconds
      const timer = setTimeout(() => {
        setShowFlashMessage(false);
        if (deleteRoleSuccessMessage || deleteRoleError) {
          dispatch(clearDeleteRoleMessages());
        }
        if (updateRoleSuccessMessage || updateRoleError) {
          dispatch(clearUpdateRoleMessages());
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [
    deleteRoleSuccessMessage,
    deleteRoleError,
    updateRoleSuccessMessage,
    updateRoleError,
    dispatch,
  ]);

  const openCreateRoleModal = () => setIsCreateRoleModalOpen(true);
  const closeCreateRoleModal = () => setIsCreateRoleModalOpen(false);

  const openEditRoleModal = (role) => {
    setSelectedRole(role);
    setIsEditRoleModalOpen(true);
  };
  const closeEditRoleModal = () => setIsEditRoleModalOpen(false);

  const handleCreateRole = async (roleData) => {
    await dispatch(createRole(roleData));
    closeCreateRoleModal();
  };

  const handleUpdateRole = async (updatedRole) => {
    await dispatch(updateRoleName(updatedRole));
    closeEditRoleModal();
  };

  const toggleStatus = async (roleId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    await dispatch(updateRoleStatus({ id: roleId, status: newStatus }));
  };

  const handleDeleteRole = async (roleId) => {
    try {
      const resultAction = await dispatch(deleteRole(roleId));

      // The flash message is handled by the useEffect above
    } catch (error) {
      console.error("Error deleting role:", error);
      setFlashMessage("An unexpected error occurred.");
      setShowFlashMessage(true);

      // Automatically hide the message after 3 seconds
      setTimeout(() => setShowFlashMessage(false), 3000);
    }
  };

  const handleDropdownClick = (index) =>
    setDropdownVisible(dropdownVisible === index ? null : index);

  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = roles.slice(indexOfFirstRole, indexOfLastRole);

  const totalPages = Math.ceil(roles.length / rolesPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="m-5 container mx-auto p-6 bg-gray-100">
      {/* Flash Message */}
      {showFlashMessage && (
        <div className="bg-green-500 text-white p-3 mb-4 text-center rounded-md">
          {flashMessage}
        </div>
      )}

      <h1 className="text-center text-2xl font-bold mb-6">Role Details</h1>
      <div className="flex justify-between mb-4">
        <button
          className="bg-[#82A943] text-white px-4 py-2 rounded hover:bg-green-700"
          onClick={openCreateRoleModal}
        >
          + Create Role
        </button>
      </div>
      {status === "loading" ? (
        <p>Loading...</p>
      ) : (
        <div className="bg-white shadow-md rounded-lg p-6">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Role ID
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Role Name
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {currentRoles.map((role, index) => (
                <tr key={role._id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b border-gray-300">
                    {role.roleId}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {role.roleName}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {role.status}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300 text-right relative">
                    <button
                      onClick={() => handleDropdownClick(index)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      &#x22EE;
                    </button>
                    {dropdownVisible === index && (
                      <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={() => toggleStatus(role._id, role.status)}
                        >
                          {role.status === "active" ? "Deactivate" : "Activate"}
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={() => openEditRoleModal(role)}
                        >
                          Edit Role
                        </button>
                        <button
                          className="block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100"
                          onClick={() => handleDeleteRole(role._id)}
                        >
                          Delete Role
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={prevPage}
          className={`${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-[#82A943] hover:bg-green-700"
          } text-white font-bold py-2 px-4 rounded-l disabled:opacity-50`}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={nextPage}
          className={`${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-[#82A943] hover:bg-green-700"
          } text-white font-bold py-2 px-4 rounded-r disabled:opacity-50`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {isCreateRoleModalOpen && (
        <CreateRoleModal
          isOpen={isCreateRoleModalOpen}
          onClose={closeCreateRoleModal}
          onCreateRole={handleCreateRole}
        />
      )}

      {isEditRoleModalOpen && selectedRole && (
        <EditRoleModal
          isOpen={isEditRoleModalOpen}
          role={selectedRole}
          onClose={closeEditRoleModal}
          onUpdateRole={handleUpdateRole}
        />
      )}
    </div>
  );
};

export default CreateRole;
