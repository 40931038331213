import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./Api";

// Async thunk to handle login
export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/user/login", {
        email: `${username.toLowerCase()}`,
        password: password,
      });

      const data = response.data;
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user)); // Store user as string

      // Update axiosInstance header
      axiosInstance.defaults.headers.Authorization = `Bearer ${data.token}`;

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

// Async thunk to handle logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    // Reset axiosInstance header
    delete axiosInstance.defaults.headers.Authorization;

    dispatch(authSlice.actions.clearAuth());
  }
);

// Restore token from localStorage if it exists (e.g., after page reload)
export const restoreAuth = createAsyncThunk("auth/restoreAuth", async () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  if (token && user) {
    // Set token in axios instance
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
    return { token, user };
  }

  return null; // No token found
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null, // Initialize from localStorage
    token: null, // Initialize token from localStorage
    loading: false,
    error: null,
  },
  reducers: {
    clearAuth: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to login";
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
      })
      .addCase(restoreAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreAuth.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.user = action.payload.user;
          state.token = action.payload.token;
        }
      })
      .addCase(restoreAuth.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { clearAuth } = authSlice.actions;

export default authSlice.reducer;
