import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetLeaves, fetchLeaveRequests } from "../redux/LeaveRequestslice"; // Import the resetLeaves action

const ResetLeave = () => {
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.leaveRequests);

  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleReset = () => {
    dispatch(resetLeaves())
      .then(() => {
        // Dispatch fetchLeaveRequests after resetLeaves is completed
        dispatch(fetchLeaveRequests());
      })
      .catch((error) => {
        console.error("Error resetting leaves: ", error);
      });
    setShowModal(false); // Close modal after action
  };

  const openModal = () => {
    setShowModal(true); // Open the modal when clicking the reset button
  };

  const closeModal = () => {
    setShowModal(false); // Close modal without resetting
  };

  return (
    <div>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
        onClick={openModal}
        disabled={loading} // Disable button when loading
      >
        {loading ? "Resetting..." : "Reset All Leaves"}
      </button>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {/* Modal for confirmation */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px] text-center">
            <h2 className="text-xl font-semibold mb-4">
              Warning: Reset All Leaves
            </h2>
            <p className="text-gray-700 mb-6">
              This action will remove all leave history and reset the annual
              leave days for all employees. Are you sure you want to proceed?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
                onClick={handleReset}
                disabled={loading}
              >
                Yes, Reset
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
                onClick={closeModal}
              >
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetLeave;
