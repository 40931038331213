import React, { useState } from "react";

const PasswordResetModal = ({ isOpen, onClose }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    // Client-side validation to check if newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match");
      setSuccess("");
      return;
    }

    try {
      const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage

      const response = await fetch(
        "https://hub.exinelabs.com/user/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccess(data.message);
        alert("Password successfully reset"); // Show alert message
        setError("");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        onClose(); // Close the modal after successful password reset
      } else {
        setError(data.message || "Error resetting password");
        setSuccess("");
      }
    } catch (error) {
      setError("Error resetting password");
      setSuccess("");
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-96">
        <h2 className="text-xl mb-4">Reset Password</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}
        <form onSubmit={handlePasswordReset}>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Enter current password"
            className="w-full px-4 py-2 mb-4 border rounded"
            required
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            className="w-full px-4 py-2 mb-4 border rounded"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            className="w-full px-4 py-2 mb-4 border rounded"
            required
          />
          {/* Note about strong password */}
          <p className="text-gray-600 text-sm mb-4">
            Note: Use a strong password with a mix of letters, numbers, and
            special characters, and atleast 6 characters
          </p>
          <div className="flex justify-end">
            <button
              type="button"
              className="px-4 py-2 mr-2 bg-gray-300 rounded"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetModal;
